import React, { Component } from "react";
import Card from "react-md/lib/Cards/Card";
import CardText from "react-md/lib/Cards/CardText";
import CardTitle from "react-md/lib/Cards/CardTitle";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Media from "react-md/lib/Media/Media";
import MediaOverlay from "react-md/lib/Media/MediaOverlay";
import Divider from "react-md/lib/Dividers/Divider";
import UserLinks from "../UserLinks";
import config from "../../../data/SiteConfig";
import "./Escrow.scss";

class Escrow extends Component {
  render() {
    return (
      <div className="md-grid mobile-fix">
        <Grid className="wrapper">
          <Cell size={3}></Cell>
          <Cell size={6}>
            <h2 className="md-headline">Escrow Services</h2>
            <p className="md-body-1">
              The <b>Escrow Agent</b> ​is a neutral party to your transaction. <b>With Aviators Title and Escrow</b> ​as your agent, neutral doesn’t mean uninterested. By maintaining a small client base, I’m able to give each transaction my full time and attention. Access to me isn’t limited by the clock, the calendar, or the amount of funds in escrow. And though certain standards and protocols must be adhered to, I understand the need for flexibility. There’s no such thing as a “cookie cutter” aircraft transaction. That’s why I strive to customize my services to meet the needs of the customer.
              <br /><br/>
              <b>Buyers</b> and <b>Lenders</b> alike trust <b>Aviators Title and Escrow</b> ​to hold ​deposits, loan proceeds and purchase funds in readiness for closing. The peace of mind that comes with knowing the status of title, liens and encumbrances (as reflected by FAA and Int’l Registry records) makes escrow a “must have” for many buyers and their lenders. With a formal closing through escrow, release of funds is coordinated with the FAA and International Registry filing of the buyer’s ownership interest and the lender’s security interest. It’s all very simple, seamless, safe and secure.​
              <br /><br/>
              <b>Sellers, Brokers & Dealers</b> ​know scammers use checks for fraud. <b>Aviators Title and Escrow​</b> will transfer “<b>good funds</b>” directly to your account via Fedwire <b>BEFORE</b> you hand over those keys. Sure, the buyer could wire purchase funds directly to your account, but do you really want to give your bank account information to a total stranger? I don’t think so. And what about that <b>Bill of Sale</b>? Won’t you rest easier knowing it’s on file with FAA before the buyer flies off to “who knows where” doing “who knows what” while the FAA still thinks you own the aircraft?
            </p>
          </Cell>
          <Cell size={3}></Cell>
        </Grid>
        <Card className="md-grid md-cell--8">
          <div className="centered">
            <CardText>
              <p className="md-body-1 centered">
                I don’t have a 3700 sq. ft. office building, 37 toll-free numbers, or 37 people on staff. <b>What I do have is 37 years of experience in this business</b>. Not one single person closing aircraft transactions today can top that. And I’m pretty sure none of them ever piloted an airplane… or taught someone else how to fly one. I’m even willing to bet many of them have never even flown in a general aviation aircraft. Sure hope I’m wrong about that last one.
              </p>
            </CardText>
          </div>
        </Card>
      </div>
    );
  }
}

export default Escrow;
